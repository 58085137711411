import React, { useState } from "react";
import Skill from "../../Skill";
import "./css/imgStyle.css";

export default function Skills() {
  const [skill] = useState([
    {
      img: "azure",
      name: "Azure",
      percentage: "100",
    },
    {
      img: "mongo",
      name: "MongoDB",
      percentage: "100",
    },
    {
      img: "firebase",
      name: "Firebase",
      percentage: "100",
    },
    {
      img: "sanity",
      name: "Sanity",
      percentage: "100",
    },
    {
      img: "mysql",
      name: "MySQL",
      percentage: "100",
    },
    {
      img: "maria",
      name: "MariaDB",
      percentage: "100",
    },
    {
      img: "phpMyAdmin",
      name: "PhpMyAdmin",
      percentage: "100",
    },
    {
      img: "illustrator",
      name: "Illustrator",
      percentage: "100",
    },
    {
      img: "photoshop",
      name: "Photoshop",
      percentage: "100",
    },
    {
      img: "xd",
      name: "XD",
      percentage: "100",
    },
    {
      img: "figma",
      name: "Figma",
      percentage: "100",
    },
    {
      img: "netlify",
      name: "Netlify",
      percentage: "100",
    },
    {
      img: "heroku",
      name: "Heroku",
      percentage: "100",
    },
    {
      img: "github",
      name: "Github",
      percentage: "100",
    },
    {
      img: "git",
      name: "Git",
      percentage: "100",
    },
    {
      img: "npm",
      name: "npm & yarn",
      percentage: "100",
    },
    {
      img: "material",
      name: "Material UI",
      percentage: "100",
    },
    {
      img: "gsap",
      name: "GSAP",
      percentage: "100",
    },
    {
      img: "framer",
      name: "Framer",
      percentage: "100",
    },
    {
      img: "stripe",
      name: "Stripe",
      percentage: "100",
    },
  ]);
  return (
    <div className="skillsContainer">
      {skill.map((singleSkill) => (
        <Skill
          key={singleSkill.name}
          imgLogo={singleSkill.img}
          name={singleSkill.name}
          percentage={singleSkill.percentage}
        />
      ))}
    </div>
  );
}
