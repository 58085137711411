// src/data/projectsData.js
import { FaGithub, FaRegEye } from "react-icons/fa";

const projectsData = [
  // First Project: Shc
  //

  // Second Project: Carmine
  {
    id: "carmine",
    backgroundColor: "#000",
    defaultBackgroundColor: "#222", // Previous component's background color (Shc)
    colors: {
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "rgb(233, 209, 255)",
      buttonColor: "rgb(233, 209, 255)",
      buttonBorderColor: "rgb(233, 209, 255)",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "rgb(233, 209, 255)",
      buttonColor: "rgb(233, 209, 255)",
      buttonBorderColor: "rgb(233, 209, 255)",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Full-Stack Web-App",
    h2Text: "Carmine Sembra Brooklyn",
    description:
      "E-commerce site developed using React for the frontend, Node.js and Express for the backend, and MongoDB for the database. The project also involved RESTful APIs for communication between the frontend and backend, ensuring a dynamic and efficient user experience.",
    imageClass: "carmine",
    buttons: [
      {
        href: "https://github.com/DantasticWebSolutions/carmine-sembra-brooklyn",
        label: "Github",
        icon: FaGithub,
      },
    ],
  },
  // Bryan GR
  {
    id: "bryan",
    backgroundColor: "#fff",
    defaultBackgroundColor: "#000",
    colors: {
      textColor: "var(--text-color)",
      h5Color: "#000",
      h2Color: "#ff6b00",
      buttonColor: "#ff6b00",
      buttonBorderColor: "#ff6b00",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Front-end Portfolio",
    h2Text: "Bryan Giraldo Portfolio",
    description:
      "Implemented a web designer’s custom layout using React and Styled Components, transforming the design into a fully responsive and interactive website.",
    imageClass: "bryan",
    buttons: [
      {
        href: "https://bryangr.com",
        label: "Live",
        icon: FaRegEye,
      },
    ],
  },
  {
    id: "nicola",
    backgroundColor: "#000",
    defaultBackgroundColor: "#fff",
    colors: {
      textColor: "#fff",
      h5Color: "#fff",
      h2Color: "#cd0000",
      buttonColor: "#cd0000",
      buttonBorderColor: "#cd0000",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Front-end Portfolio",
    h2Text: "Nicola Rossi Portfolio",
    description:
      "Implemented a web designer’s custom layout using Next and Tailwind, transforming the design into a fully responsive and interactive website.",
    imageClass: "nicola",
    buttons: [
      {
        href: "https://portfolionicolarossi.netlify.app/",
        label: "Live",
        icon: FaRegEye,
      },
    ],
  },
  {
    id: "austin",
    backgroundColor: "#dbe1c7",
    defaultBackgroundColor: "#000",
    colors: {
      textColor: "#7d826d",
      h5Color: "#000",
      h2Color: "#163a45",
      buttonColor: "#163a45",
      buttonBorderColor: "#163a45",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Front-end Portfolio",
    h2Text: "Caesar Music Portfolio",
    description:
      "Implemented a custom layout using Next and Tailwind, transforming the design into a fully responsive and interactive website.",
    imageClass: "austin",
    buttons: [
      {
        href: "https://caezarmusic.com/",
        label: "Live",
        icon: FaRegEye,
      },
    ],
  },
  {
    id: "nimbins",
    backgroundColor: "#f7f7f8",
    defaultBackgroundColor: "#dbe1c7",
    colors: {
      textColor: "#7d826d",
      h5Color: "#000",
      h2Color: "#4b4ea9",
      buttonColor: "#4b4ea9",
      buttonBorderColor: "#4b4ea9",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Front-end Website",
    h2Text: "Nimbin's Cafe",
    description:
      "A front-end webiste, created for a small coffeshop to showcase their manu in Brescia Italy",
    imageClass: "nimbins",
    buttons: [
      {
        href: "https://nimbinscafe.com/",
        label: "Live",
        icon: FaRegEye,
      },
    ],
  },
  {
    id: "cibo",
    backgroundColor: "#000",
    defaultBackgroundColor: "#f7f7f8",
    colors: {
      textColor: "#7d826d",
      h5Color: "#7d826d",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Shc)
      textColor: "#b9b9b9",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Front-end Website",
    h2Text: "Cibo Restaurant",
    description:
      "A front-end webiste created to allow a restaurant in London to have an online presence.",
    imageClass: "cibo",
    buttons: [
      {
        href: "https://cibonext.netlify.app/",
        label: "Live",
        icon: FaRegEye,
      },
    ],
  },

  // Third Project: Meal
  {
    id: "meal",
    backgroundColor: "#52B6DE",
    defaultBackgroundColor: "#000", // Previous component's background color (Carmine)
    colors: {
      textColor: "#fdf5eb",
      h5Color: "#fff",
      h2Color: "#f06e1d",
      buttonColor: "#f06e1d",
      buttonBorderColor: "#f06e1d",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    defaultColors: {
      // Colors matching the previous component (Carmine)
      textColor: "#000",
      h5Color: "#fff",
      h2Color: "rgb(233, 209, 255)",
      buttonColor: "rgb(233, 209, 255)",
      buttonBorderColor: "rgb(233, 209, 255)",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Serverless Web-App",
    h2Text: "Weekly Meal Planner",
    description:
      "An awesome meal planner to help customers live a healtier and more organised life",
    imageClass: "meal",
    buttons: [
      {
        href: "https://github.com/DantasticWebSolutions/weekly-planner",
        label: "Github",
        icon: FaGithub,
      },
      {
        href: "https://dantasticweeklyplanner.netlify.app/login",
        label: "Live",
        icon: FaRegEye,
      },
    ],
  },

  // Fourth Project: Map
  {
    id: "map",
    backgroundColor: "#CAE9FF",
    defaultBackgroundColor: "#52B6DE", // Previous component's background color (Meal)
    colors: {
      textColor: "#3F8098",
      h5Color: "#5FA8D3",
      h2Color: "#1B4965",
      buttonColor: "#1B4965",
      buttonBorderColor: "#1B4965",
      button2Color: "#5FA8D3",
      button2BorderColor: "#5FA8D3",
    },
    defaultColors: {
      // Colors matching the previous component (Meal)
      textColor: "#fff",
      h5Color: "#fff",
      h2Color: "#fff",
      buttonColor: "#fff",
      buttonBorderColor: "#fff",
      button2Color: "#fff",
      button2BorderColor: "#fff",
    },
    h5Text: "Uni Assessment, Back-End Development",
    h2Text: "Searchable Map",
    description:
      "An open-source map application to perform CRUD operations on places I've visited and to keep track of my favorite spots around the world.",
    imageClass: "map",
    buttons: [
      {
        href: "https://github.com/DantasticWebSolutions/pointOfInterest",
        label: "Github",
        icon: FaGithub,
      },
    ],
  },

  // Fifth Project: Expenses
  {
    id: "expenses",
    backgroundColor: "#fff",
    defaultBackgroundColor: "#CAE9FF", // Previous component's background color (Map)
    colors: {
      textColor: "#3F8098",
      h5Color: "#5FA8D3",
      h2Color: "#1B4965",
      buttonColor: "#1B4965",
      buttonBorderColor: "#1B4965",
      button2Color: "#5FA8D3",
      button2BorderColor: "#5FA8D3",
    },
    defaultColors: {
      // Colors matching the previous component (Map)
      textColor: "#3F8098",
      h5Color: "#5FA8D3",
      h2Color: "#1B4965",
      buttonColor: "#1B4965",
      buttonBorderColor: "#1B4965",
      button2Color: "#5FA8D3",
      button2BorderColor: "#5FA8D3",
    },
    h5Text: "Uni Assessment, Back-End Development",
    h2Text: "Expenses Tracker",
    description:
      "A web application created for a school assignment to keep track of all expenses and monitor account balances.",
    imageClass: "expensesTracker",
    buttons: [
      {
        href: "https://github.com/DantasticWebSolutions/dantastic-expense-tracker",
        label: "Github",
        icon: FaGithub,
      },
    ],
  },
];

export default projectsData;
